import React, { useContext, useEffect, useState, useCallback } from 'react';
import { differenceInMinutes, differenceInSeconds, format } from 'date-fns';
import mapboxgl from 'mapbox-gl';
import { UserContext } from "../UserContext";
import 'mapbox-gl/dist/mapbox-gl.css';
import { InputSwitch } from 'primereact/inputswitch';

export const GateNowMap = () => {
    const [map1, setMap1] = useState(null);
    const [map2, setMap2] = useState(null);
    const [mapSwitch, setMapSwitch] = useState(false);
    const [gates, setGates] = useState([]);
    const [tables, setTables] = useState([]);
    const user = useContext(UserContext);
    const landSideCenter = [-80.256278, 40.495781];
    const airSideCenter = [-80.245493, 40.495604];
    const landSideZoom = 16.74;
    const airSideZoom = 16.30;
    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
    });

    const getMarkerBackground = (stateDescription) => {
        if (stateDescription.includes("Closed During Regular Hours")) {
            return stateDescription.every(status => status === "Closed During Regular Hours") ? 'red' : '#FFA200';
        } else if (stateDescription.includes("Open Outside Regular Hours")) {
            return '#00FFFF'; // light blue
        } else if (stateDescription.includes("Open") || stateDescription.includes("Closed")) {
            return '#1c9600'; // light green
        }
        return 'black';
    };

    const showPopup = (markerElement, location, tenant, map) => {
        const unitsHtml = tenant.Units.map(unit => (
            `<div style="background-color:${unit.StateDescription === 'Closed During Regular Hours' ? '#f8cecc' : unit.StateDescription === 'Open Outside Regular Hours' ? '#ADD8E6' : '#fff2cc'}; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 8px; padding: 4px; margin:5px; color: #333333;">
                ${(() => {
                const diffInMinutes = differenceInMinutes(new Date(), new Date(unit.Timestamp));
                const diffInSeconds = differenceInSeconds(new Date(), new Date(unit.Timestamp));
                if (diffInMinutes > 60) {
                    return `<div style="color: red; font-weight: bolder;">${diffInMinutes} Minutes ago</div>`;
                } else if (diffInMinutes > 0) {
                    return `<div>${diffInMinutes} Minutes ago</div>`;
                } else {
                    return `<div>${diffInSeconds} Seconds ago</div>`;
                }
            })()}
                <div>${unit.PlaceName}</div>
                <div>${unit.StateDescription}</div>
            </div>`
        )).join('');
        popup.setLngLat([location.lng, location.lat])
            .setHTML(`
                <div class='cardPopup'>
                    <div>
                        <h6>${tenant.SQLTenantName}</h6>
                    </div>
                    <div style="margin-top:-10px">${tenant.StartTimeString} - ${tenant.EndTimeString}</div>
                    <div>${unitsHtml}</div>
                </div>
            `).addTo(map);
    };
    const hidePopup = () => {
        popup.remove();
    };

    const addMarkers = useCallback((map) => {
        if (!map) return;
        const markers = Object.keys(gates).map(key => {
            const tenant = gates[key];
            const { SQLTenantName, Units, MaxLastSensorReadingMinutesAgo, StateDescription } = tenant;
            const markerElement = document.createElement('div');
            markerElement.className = MaxLastSensorReadingMinutesAgo > 60 ? 'acaa-marker-dashed' : 'acaa-marker';
            markerElement.style.background = getMarkerBackground(StateDescription);

            const location = Units.length > 0 ? { lng: Units[0].Long, lat: Units[0].Lat } : null;

            if (location) {
                const marker = new mapboxgl.Marker(markerElement)
                    .setLngLat([location.lng, location.lat])
                    .addTo(map);

                const handleMouseEnter = () => showPopup(markerElement, location, tenant, map);
                const handleMouseLeave = () => hidePopup();

                markerElement.addEventListener('mouseenter', handleMouseEnter);
                markerElement.addEventListener('mouseleave', handleMouseLeave);

                return { marker, markerElement, handleMouseEnter, handleMouseLeave };
            }

            return null;
        }).filter(marker => marker !== null);
        return () => {
            markers.forEach(({ marker, markerElement, handleMouseEnter, handleMouseLeave }) => {
                marker.remove();
                markerElement.removeEventListener('mouseenter', handleMouseEnter);
                markerElement.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, [gates]);

    const fetchConcessionsGateData = () => {
        popup.remove();
        user.apiCall(`getconcessionsgatenow`).then(res => {
            setTables(res.data);
        }).catch((err) => console.log("Error fetching data ", err));
    };

    useEffect(() => {
        const structureTables = tables.map(data => ({
            ...data,
            StateDescription: data.StateDescription === "Closed Scheduled Open" ? "Closed During Regular Hours" :
                data.StateDescription === "Open Scheduled Closed" ? "Open Outside Regular Hours" :
                    data.StateDescription,
            StartTimeString: format(new Date(`2000-01-01T${data.StartTimeString}`), 'hh:mm a'),
            EndTimeString: format(new Date(`2000-01-01T${data.EndTimeString}`), 'hh:mm a')
        }));
        const newTable = structureTables.reduce((acc, element) => {
            const temp = {
                StateDescription: element.StateDescription,
                StartTimeString: element.StartTimeString,
                EndTimeString: element.EndTimeString,
                IsDiscrepency: element.IsDiscrepency,
                Timestamp: element.Timestamp,
                Lat: element.Lat,
                Long: element.Long,
                PlaceName: element.PlaceName
            };
            const key = element.SQLTenantName;
            if (acc[key]) {
                acc[key] = {
                    ...acc[key],
                    IsDiscrepency: acc[key].IsDiscrepency || element.IsDiscrepency,
                    StateDescription: [...acc[key].StateDescription, element.StateDescription],
                    Units: [...acc[key].Units, temp],
                    MaxLastSensorReadingMinutesAgo: Math.max(acc[key].MaxLastSensorReadingMinutesAgo, differenceInMinutes(new Date(), new Date(element.Timestamp)))
                };
            } else {
                acc[key] = {
                    SQLTenantName: key,
                    IsDiscrepency: element.IsDiscrepency,
                    StateDescription: [element.StateDescription],
                    StartTimeString: element.StartTimeString,
                    EndTimeString: element.EndTimeString,
                    MaxLastSensorReadingMinutesAgo: differenceInMinutes(new Date(), new Date(element.Timestamp)),
                    Units: [temp]
                };
            }
            return acc;
        }, {});

        setGates(newTable);
    }, [tables]);




    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoidGJ1ZG5leSIsImEiOiJja3czd2wyZDdhc296Mm5xcDR0eW5mNTd6In0.Cx2XugeiPydpE72bh4jQbw';
        const initializeMap = () => {
            const container1 = document.getElementById('map1');
            const container2 = document.getElementById('map2');

            // Clear the container to avoid the warning
            container1.innerHTML = '';
            container2.innerHTML = '';

            const mapInstance1 = new mapboxgl.Map({
                container: 'map1',
                style: mapSwitch ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/light-v10',
                center: landSideCenter,
                zoom: landSideZoom
            });

            const mapInstance2 = new mapboxgl.Map({
                container: 'map2',
                style: mapSwitch ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/light-v10',
                center: airSideCenter,
                zoom: airSideZoom
            });

            setMap1(mapInstance1);
            setMap2(mapInstance2);
        };

        initializeMap();
        return () => {
            if (map1) {
                map1.remove();
            }
            if (map2) {
                map2.remove();
            }
        };
    }, [mapSwitch]);

    useEffect(() => {
        fetchConcessionsGateData();
        const interval = setInterval(fetchConcessionsGateData, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (map1) addMarkers(map1);
        if (map2) addMarkers(map2);
    }, [map1, map2, gates, addMarkers]);

    const changeMapStyle = useCallback((value) => {
        setMapSwitch(value);
    }, []);

    return (
        <div id="GateNowMap" style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <div id="Satellite" style={{ position: 'absolute', zIndex: 2, backgroundColor: 'white', padding: '10px', width: '200px', opacity: 0.75, marginTop: '10px', marginLeft: '10px' }}>
                <div className="switch-text">
                    Satellite View
                </div>
                <InputSwitch checked={mapSwitch} onChange={(e) => changeMapStyle(e.value)} />
            </div>

            <div style={{ display: 'flex', width: '100%', height: '84vh', position: 'relative', marginBottom: '0px', paddingBottom: '0px' }}>
                <div style={{ width: '50%', height: '100%', border: "1px solid lightgrey", position: 'relative' }}>
                    <div id="map1" style={{ width: '100%', height: '100%' }} />
                    <div id="Landside" style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', padding: '10px', width: '180px', height: '50px', opacity: 0.85, zIndex: 3, background: 'white', textAlign: 'center' }}>
                        <h3 style={{ color: 'black' }}>Landside</h3>
                    </div>
                </div>
                <div style={{ width: '50%', height: '100%', border: "1px solid lightgrey", position: 'relative', marginLeft: '15px' }}>
                    <div id="map2" style={{ width: '100%', height: '100%' }} />
                    <div id="Airside" style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', padding: '10px', width: '180px', height: '50px', opacity: 0.85, zIndex: 3, background: 'white', textAlign: 'center' }}>
                        <h3 style={{ color: 'black' }}>Airside</h3>
                    </div>
                </div>
            </div>
            <div className="legend">
                <div>
                    <div className="legend-item" style={{ backgroundColor: 'red', marginBottom: '2px' }}></div>
                    <div className="legend-text">&nbsp;&nbsp;Closed during regular hours</div>
                </div>
                <div>
                    <div className="legend-item" style={{ backgroundColor: '#FFA200', marginBottom: '2px' }}></div>
                    <div className="legend-text">&nbsp;&nbsp;Partially closed during regular hours</div>
                </div>
                <div>
                    <div className="legend-item" style={{ backgroundColor: '#00FFFF', marginBottom: '2px' }}></div>
                    <div className="legend-text">&nbsp;&nbsp;Partially open during closed hours</div>
                </div>
                <div>
                    <div className="legend-item" style={{ backgroundColor: '#1c9600' }}></div>
                    <div className="legend-text">&nbsp;&nbsp;Open or closed as scheduled</div>
                </div>
            </div>
        </div>
    );
};
