import React, { useState } from 'react';
import AppSubmenu from './AppSubmenu';
const AppMenu = (props) => {

	return (
		<span >
			<AppSubmenu setTenant={props.setTenant}
				user={props.user}
				showTabs={true}
				className="layout-menu layout-main-menu clearfix"
				items={props.model}
				menuMode={props.menuMode}
				menuActive={props.active}
				root
				onMenuitemClick={props.onMenuitemClick}
				onRootMenuitemClick={props.onRootMenuitemClick} />
		</span>
	);
}

export default AppMenu;
