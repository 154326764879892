import { ExportFooterButtons } from '../components/ExportFooterButtons';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export const ConcessionsData = ({
    dt,
    changeObject,
    filteredData,
    footerTemplate,
    addOrUpdateRecords,
    exportColumns,
    dateOnlyTemplate,
    dateFilterTemplate,
    monetaryTemplate,
    inputNumericEditor,
    sumMonetrary,
    sumTransaction,
    modByEmail,
    setModByEmail,
    tranDtFlt,
    setTranDtFlt,
    lastModDtFlt,
    setLastModDtFlt
})=>{
    const [first, setFirst] = useState(0); // Add state for pagination
    const exportTemplate = ()=>{
        return(
            <ExportFooterButtons data={filteredData}
                name="concessions"
                columns={exportColumns}
                selection={true}
                tableRef={dt}
            />
        )
    }
    return(
        <div id="ConcessionsData">
            <div>
                <div className='grid'>
                    <div className='col'>
                        <div className="p-field">
                            <span className="p-float-label">
                                <InputText id="name" value={changeObject?.name}  className='p-float-label-input-text' disabled/>
                                <label htmlFor="name">Name</label>
                            </span>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="p-field">
                            <span className="p-float-label">
                            <InputText id="unit" value={changeObject?.unitName}  className='p-float-label-input-text' disabled/>
                                <label htmlFor="unit">Unit</label>
                            </span>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="p-field">
                            <span className="p-float-label">
                            <InputText id="tenantType" value={changeObject?.tenantTypeName}  className='p-float-label-input-text' disabled/>
                                <label htmlFor="tenantType">Tenant Type</label>
                            </span>
                        </div>
                    </div>
                </div>
                <DataTable ref={dt}
                    value={filteredData}
                    rows={20} // Default display 20 records
                    size="small"
                    rowsPerPageOptions={[20, 50, 100]} // Rows per page options
                    groupRowsBy="week"
                    rowGroupFooterTemplate={footerTemplate}
                    rowGroupMode="subheader"
                    editMode="row"  
                    showGridlines
                    onRowEditComplete={(props) => addOrUpdateRecords(props)}
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Transactions"
                    paginator
                >
                    <Column field="transactionsDate"
                        header="Date"
                        body={(d) => dateOnlyTemplate(d, "transactionsDate")}
                        align='center'
                        alignHeader='center'
                        editor={(d) => dateOnlyTemplate(d, "transactionsDate")}
                        filterElement={() => dateFilterTemplate(tranDtFlt, setTranDtFlt)}
                        showFilterMenu={false}
                        filter
                        sortable
                    />
                    <Column alignHeader='center'
                        align='center'
                        field="monetaryAmount"
                        sortable
                        body={monetaryTemplate}
                        editor={(props) => inputNumericEditor(props, 'monetaryAmount')}
                        footer={sumMonetrary}
                        header="Total Sales"
                    />
                    <Column alignHeader='center'
                        align='center'
                        field="numTransactions"
                        sortable
                        editor={(props) => inputNumericEditor(props, 'numTransactions')}
                        footer={sumTransaction}
                        header="Total Transactions"
                    />
                    <Column field="lastModifiedByEmail"
                        header="Last Modified By"
                        alignHeader='center'
                        align='center'
                        filterPlaceholder="Search by email"
                        filterElement={
                            <InputText value={modByEmail}
                                onChange={(e) => setModByEmail(e.target.value)}
                            />
                        }
                        showFilterMenu={false}
                        filter
                        sortable
                    />
                    <Column field="lastModified"
                        header="Last Modified Date"
                        body={(d) => dateOnlyTemplate(d, "lastModified")}
                        alignHeader='center'
                        align='center'
                        editor={(d) => dateOnlyTemplate(d, "lastModified")}
                        filterElement={() => dateFilterTemplate(lastModDtFlt, setLastModDtFlt)}
                        showFilterMenu={false}
                        filter
                        sortable
                    />
                    <Column alignHeader='center'
                        align='center'
                        rowEditor
                        header={exportTemplate}
                        bodyStyle={{ textAlign: 'center' }}
                    />
                </DataTable>
            </div>
        </div>
    )
};