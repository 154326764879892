import React, { useState, useEffect, useRef,useContext } from 'react';
import { UserContext } from "../UserContext";
import { dateFromUtcTemplate, dateOnlyTemplate } from '../service/HelpersService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ExportFooterButtons } from '../components/ExportFooterButtons';

const exportColumns = [
	{ title: "Tenant", dataKey: "tenant" },
	{ title: "Vendor", dataKey: "vendorName" },
	{ title: "Drop Off Date", dataKey: "dropOffDate" },
	{ title: "Service Date", dataKey: "serviceDate" },
	{ title: "Repair Category", dataKey: "repairCategory" }
];

const tooltipMsg = "List of maintenance requests submitted from the tenants.\n" +
	"Entries in the table can be filtered by typing in the keyword search field.\n" +
	"Clicking on a column header will sort the table based on that column, clicking again will filter in reverse."

export const ToolDrop = () => {
	const user = useContext(UserContext)
	const [entries, setEntries] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('')
	const [filteredData, setFilteredData] = useState([]);
	const [filters, setFilters] = useState({
		'Tenant': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'vendorName': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'serviceDate': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'repairCategory': { value: null, matchMode: FilterMatchMode.CONTAINS }
	});

	const table = useRef(null);

	useEffect(() => {
		user.apiCall(`tooldrop`)
			.then((res) => {
				setEntries(res.data);
			})
		initFilters()
	}, [])

	const dateFilterTemplate = (options) => (
		<Calendar value={options.value}
			onChange={(e) => options.filterCallback(e.value, options.index)}
			selectionMode="range"
			readOnlyInput
		/>
	);

	const dateBetween = (value, filters) => {
		if (filters && filters[0] && filters[1]) {
			return value >= filters[0] && value <= filters[1]
		}
		return true;
	}

	const initFilters = () => {
		setFilters({
			'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
			'tenant': { value: null, matchMode: FilterMatchMode.CONTAINS },
			'vendorName': { value: null, matchMode: FilterMatchMode.CONTAINS },
			'serviceDateEst': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
			'requestDate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
		});
		setGlobalFilterValue('');
	}

	const renderHeader = () => (
		<div className="tooldrop-header">
			<Button type="button"
				icon="pi pi-filter-slash"
				label="Clear"
				onClick={clearFilter}
			/>
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText value={globalFilterValue}
					onChange={onGlobalFilterChange}
					placeholder="Keyword Search"
				/>
			</span>
		</div>
	);

	const clearFilter = () => {
		initFilters();
	}

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };
		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	}

	return (
		<Card>
			<h1 style={{display: "inline-block"}}>Tool Drop</h1>
			<Tooltip target=".custo-target-icon" style={{ maxWidth: "30vw" }} />
			<i className="custo-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
				data-pr-tooltip={tooltipMsg}
				data-pr-position="bottom"
				data-pr-at="right+5 top"
				data-pr-my="left center-2"
				style={{ fontSize: "1.8rem", cursor: 'pointer', verticalAlign: "top", margin: "8px 0 0 8px" }}>
			</i>
			<DataTable value={entries}
				ref={table}
				dataKey="Id"
				filters={filters}
				filterDisplay="menu"
				globalFilterFields={['tenant', 'firstName', 'vendorName', 'repairCategory', 'salesImpact', 'email', 'natureOfRepair']}
				onValueChange={filteredData => setFilteredData(filteredData)}
				onFilter={(e) => setFilters(e.filters)}
				footer={<ExportFooterButtons data={entries}
					name="tooldrop"
					columns={exportColumns}
					selection={false}
					tableRef={table} />}
				rows="30"
				paginator
				rowHover
				header={renderHeader}
			>
				<Column field="tenant"
					header="Tenant"
					align='center'
					alignHeader='center'
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="vendorName"
					header="Vendor"
					align='center'
					alignHeader='center'
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="serviceDate"
					header="Service Date"
					body={(d) => dateFromUtcTemplate(d, "serviceDate")}
					align='center'
					alignHeader='center'
					filterMatchMode="custom"
					filterFunction={dateBetween}
					filterElement={dateFilterTemplate}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="dropOffDate"
					header="Drop Off Date"
					body={(d) => dateFromUtcTemplate(d, "dropOffDate")}
					align='center'
					alignHeader='center'
					filterMatchMode="custom"
					filterFunction={dateBetween}
					filterElement={dateFilterTemplate}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="repairCategory"
					header="Repair Category"
					align='center'
					alignHeader='center'
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="salesImpact"
					header="Sales Impact"
					align='center'
					alignHeader='center'
					sortable
				/>
				<Column field="email"
					header="Email"
					align='center'
					alignHeader='center'
					sortable
				/>
				<Column field="requestDate"
					sortable
					align='center'
					alignHeader='center'
					body={(d) => dateOnlyTemplate(d, "requestDate")}
					filterElement={dateFilterTemplate}
					showFilterMenu={false}
					filterMatchMode="custom"
					filterFunction={dateBetween}
					filter
					header="Request Date"
				/>
				<Column field="phoneNumber"
					header="Phone Number"
					align='center'
					alignHeader='center'
				/>
				<Column field="natureOfRepair"
					header="Nature Of Repair"
					align='center'
					alignHeader='center'
				/>
			</DataTable>
		</Card>
	);
}
