import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";
import { ConcessionsContext } from '../service/ConcessionsContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { dateFromUtcTemplate } from '../service/HelpersService';

const userMsg = "User added to tenant.";
const userFail = "Failed to user to database.";
const userDeleteMsg = "User removed from tenant.";
const userDeleteFail = "Failed to remove user from tenant.";
const userAdminFail = "Emails from the @flypittsburgh domain cannot be added as tenants.";

export const Users = () => {
	const [users, setUsers] = useState([]);
	const [curUser, setCurUser] = useState();
	const [userToDelete, setUserToDelete] = useState();
	const [userToDeleteId, setUserToDeleteId] = useState();
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [tenantData, setTenantData] = useState();
	const [userToAdd, setUserToAdd] = useState('');

	const user = useContext(UserContext);
	const global = useContext(ConcessionsContext);

	const tenant = user.tenant;
	if (!curUser) {
		setCurUser(user)
	}

	useEffect(() => {
		user.apiCall('tenants/' + tenant + '/users').then(res => {
			setUsers(res.data)
		})

		user.apiCall('tenants/' + tenant).then(res => {
			setTenantData(res.data[0])
		})
	}, [tenant]);

	const addUser = () => {
		if (userToAdd.includes("@flyPittsburgh.com")) {
			global.errorToast(userAdminFail);
		}
		else {
			const userBody = {
				Email: userToAdd,
				LastLoggedIn: new Date()
			};
			user.apiCall('users', userBody, "POST")
				.then((res) => {
					if (res.status === 200) {
						const body = {
							UserId: res.data.userId,
							TenantId: tenant,
							AddedDate: new Date(),
							AddedBy: user.username,
							IsManager: false,
							UserEmail: userToAdd,
							TenantName: tenantData.name
						};
						user.apiCall('tenants/' + tenant + '/users', body, "POST")
							.then((utRes) => {
								if (utRes.status === 200) {
									global.successToast(userMsg);
									user.apiCall('tenants/' + tenant + '/users')
										.then(res => {
											setUsers(res.data)
											setUserToAdd('')
										})
								}
								else {
									global.errorToast(userFail);
								}
							})
					}
					else {
						global.errorToast(userFail);
					}
				})
		}
	}

	const deleteUser = () => {
		user.apiCall('tenants/' + tenant + '/users/' + userToDeleteId, {},"DELETE")
			.then((res) => {
				if (res.status === 200) {
					global.successToast(userDeleteMsg);
					user.apiCall('tenants/' + tenant + '/users')
						.then(res => {
							setUsers(res.data)
						})
				}
				else {
					global.errorToast(userDeleteFail);
				}
			})
	}

	const buttonBodyTemplate = (rowData) => {
		if (!rowData.isManager && rowData.email !== user.username) {
			return (
				<Button tooltip="Delete User"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						setUserToDeleteId(rowData.userId);
						setUserToDelete(rowData.email);
						setDisplayDeleteDialog(true)
					}}
					label={<i className="pi pi pi-times"></i>}
					className="p-button-rounded p-button-primary"
				/>
			)
		}
	}

	const buttonHeaderTemplate = () => (
		<Button tooltip="Add User"
			tooltipOptions={{ position: 'left' }}
			onClick={() => setDisplayDialog(true)}
			label={<i className="pi pi-user-edit"></i>}
			className="p-button-rounded p-button-primary"
		/>
	);

	const renderFooter = () => (
		<div>
			<Button label="Save"
				icon="pi pi-check"
				onClick={() => {
					addUser();
					setDisplayDialog(false)
				}}
			/>
		</div>
	);

	return (
		<div className="p-grid">
			<Dialog header="Invite User"
				visible={displayDialog}
				style={{ width: '20vw', textAlign: 'center' }}
				footer={renderFooter}
				onHide={() => setDisplayDialog(false)}
			>
                Email: <InputText value={userToAdd} onChange={(e) => setUserToAdd(e.target.value)} />
			</Dialog>
			<ConfirmDialog visible={displayDeleteDialog}
				onHide={() => setDisplayDeleteDialog(false)}
				message={"Do you want to remove " + userToDelete + " from this tenant?"}
				header="Delete Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteUser()}
				reject={null}
			/>
			<div className="p-col-12">
				<div className="card">
					<h5>Users</h5>
					<div>
						<DataTable value={users}
							sortField="email"
							sortOrder="1"
							showGridlines
							responsiveLayout="scroll"
						>
							<Column field="email"
								header="User Email"
								align='center'
								alignHeader='center'
								sortable
							/>
							<Column field="invitedBy"
								header="Invited By"
								align='center'
								alignHeader='center'
								sortable
							/>
							<Column field="lastLoggedIn"
								header="Last Logged In"
								body={(d) => dateFromUtcTemplate(d, "lastLoggedIn")}
								align='center'
								alignHeader='center'
								sortable
							/>
							<Column align='center'
								alignHeader='center'
								field="buttons"
								body={buttonBodyTemplate}
								header={buttonHeaderTemplate}
							/>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	);
}
