import { format } from 'date-fns';

export const dateFromUtcTemplate = (rowData, property) => {
	if(rowData[property] === null || rowData[property] === undefined) {
		return null;
	}
	const value = new Date(rowData[property] + "Z");
	if (isValidDate(value)) {
		return format(value, "MM/dd/y h:mmaaa");
	}
	return null;
}

export const dateOnlyTemplate = (rowData, property) => {
	if(rowData[property] === null || rowData[property] === undefined) {
		return null;
	}
	const value = new Date(rowData[property]);
	if (isValidDate(value)) {
		return format(value, "MM/dd/y");
	}
	return null;
}

function isValidDate(date) {
	return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}
