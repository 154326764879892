import React, { useState, useEffect, useRef, useContext } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
export const UsersTab = ({
    users,
    checkBodyTemplate,
    buttonBodyTemplate,
    buttonHeaderTemplate,
    dateOnlyTemplate,
    changeObject
})=>{
    return(
        <div>
            <div className='grid'>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText id="name" value={changeObject?.name}  className='p-float-label-input-text' disabled/>
                            <label htmlFor="name">Name</label>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                        <InputText id="unit" value={changeObject?.unitName}  className='p-float-label-input-text' disabled/>
                            <label htmlFor="unit">Unit</label>
                        </span>
                    </div>
                </div>
                <div className='col'>
                    <div className="p-field">
                        <span className="p-float-label">
                        <InputText id="tenantType" value={changeObject?.tenantTypeName}  className='p-float-label-input-text' disabled/>
                            <label htmlFor="tenantType">Tenant Type</label>
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '5px' }}>
                <DataTable value={users}
                    sortField="email"
                    sortOrder="1"
                    responsiveLayout="scroll"
                >
                    <Column field="email"
                        header="User Email"
                        align='center'
                        alignHeader='center'
                        sortable
                    />
                    <Column field="invitedBy"
                        header="Invited By"
                        align='center'
                        alignHeader='center'
                        sortable
                    />
                    <Column field="registered"
                        header="Registered"
                        body={checkBodyTemplate}
                        align='center'
                        alignHeader='center'
                        sortable
                    />
                    <Column field="lastLoggedIn"
                        header="Last Logged In"
                        body={(d) => dateOnlyTemplate(d, "lastLoggedIn")}
                        align='center'
                        alignHeader='center'
                        sortable
                    />
                    <Column field="buttons"
                        alignHeader='center'
                        align='center'
                        body={buttonBodyTemplate}
                        style={{ width: '10%' }}
                        header={buttonHeaderTemplate}
                    />
                </DataTable>
            </div>
        </div>
    )
}