import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext } from "../UserContext";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Calendar } from 'primereact/calendar';

export const LeaseHours = () => {

    const [table, setTable] = useState([]);
    const [changedRows, setChangedRows] = useState([]);
    const user = useContext(UserContext);

    useEffect(() => {
        user.apiCall(`getdiscrepancies`).then(res => {
            setTable(res.data);
        });
    }, []);

    useEffect(() => {
  
    }, [changedRows])

    const cellEditor = (options) => {
        const { value, editorCallback, rowData } = options;

        const handleChange = (e) => {
            let val = e.target.value;
            const parsedDate = new Date(val);
            
            // Set minimum date to unitToTenantDate
            const minDate = new Date(rowData.unitToTenantDate);

            // Convert to Eastern Standard Time (EST)
            const estDate = utcToZonedTime(parsedDate, 'America/New_York');

            // Format the date into the desired format
            const formattedDate = format(estDate, "yyyy-MM-dd'T'HH:mm:ss");
            
            editorCallback(formattedDate);
        };

        return (
            <Calendar
                id="monthyear"
                onChange={handleChange}
                
                value={value || ''}
                dateFormat='mm-dd-yy'
                style={{ marginRight: '1rem'}}
                minDate={new Date(rowData.unitToTenantDate)} // Set minDate to unitToTenantDate           
            />
        );
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        const existingValue = rowData[field];

        if (newValue !== existingValue) {
            rowData[field] = newValue;
            setTable([...table]);
            // Add changed row to the list
            const changedRow = {
                ...rowData,
                [field]: newValue // Update only the field that has been changed
            };
            setChangedRows(prevRows => [...prevRows, changedRow]);
        } else {
            event.preventDefault();
        }
    };

    return (
        <div id="Discrepancy">
            <div className="card">
                <DataTable value={table} >
                    <Column header="Tenant Name" field="name" />
                    <Column header="Tenant Type" field="tenantType" />
                    <Column header="Unit" field="unitName" />
                    <Column
                        header="Lease Start"
                        field="leaseStart"
                        body={(rowData) => {
                            const utcDate = new Date(rowData.leaseStart);
                            const estDate = utcToZonedTime(utcDate, 'America/New_York');
                            const formattedDate = format(estDate, 'yyyy-MM-dd');
                            return formattedDate;
                        }}
                        sortable
                    />
                    <Column
                        header="Lease End"
                        field="leaseEnd"
                        editor={cellEditor}
                        onCellEditComplete={onCellEditComplete}
                        body={(rowData) => {
                            const utcDate = new Date(rowData.leaseEnd);
                            const estDate = utcToZonedTime(utcDate, 'America/New_York');
                            const formattedDate = format(estDate, 'yyyy-MM-dd');
                            return formattedDate;
                        }}
                        sortable
                    />
                    <Column
                        header="Last Entry Date"
                        field="unitToTenantDate"
                        body={(rowData) => {
                            const utcDate = new Date(rowData.unitToTenantDate);
                            const estDate = utcToZonedTime(utcDate, 'America/New_York');
                            const formattedDate = format(estDate, 'yyyy-MM-dd');
                            return formattedDate;
                        }}
                        sortable
                    />
                </DataTable>
            </div>
        </div>
    );
}