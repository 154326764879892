import React, { useState, useEffect, useContext } from 'react';
import { Prompt } from "react-router-dom";
import { UserContext } from "../UserContext";
import { ConcessionsContext } from '../service/ConcessionsContext';
import { dateFromUtcTemplate, dateOnlyTemplate } from '../service/HelpersService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { Chart } from 'primereact/chart';
import { ToolDropForm } from '../components/ToolDropForm';
import { ToolDropHistory } from '../components/ToolDropHistory';
import { addDays, format } from 'date-fns';

let multiAxisOptions = {
	stacked: false,
	maintainAspectRatio: false,
	aspectRatio: .6,
	plugins: {
		legend: {
			labels: {
				color: '#495057'
			}
		}
	},
	scales: {
		x: {
			ticks: {
				color: '#495057'
			},
			grid: {
				color: '#ebedef'
			}
		},
		y: {
			type: 'linear',
			display: true,
			position: 'left',
			ticks: {
				color: '#495057'
			},
			grid: {
				color: '#ebedef'
			}
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			ticks: {
				color: '#495057'
			},
			grid: {
				drawOnChartArea: false,
				color: '#ebedef'
			}
		}
	}
};

const transactionMsg = 'Transactions written to database.';
const transactionFail = 'Failed to write to database';
const tooldropMsg = 'ToolDrop record added';
const tooldropFail = 'ToolDrop submission failed';

export const RecordEntry = () => {
	const [records, setRecords] = useState([]);
	const [recordData, setRecordData] = useState([]);
	const [sumMonetrary, setSumMonetrary] = useState(0);
	const [sumTransaction, setSumTransaction] = useState(0);
	const [promptUser, setPromptUser] = useState(false);
	const [promptUserPartial, setPromptUserPartial] = useState(false);
	const [toolDropDialogOpen, setToolDropDialogOpen] = useState(false);
	const [toolDrops, setToolDrops] = useState([]);
	const [displayHistory, setDisplayHistory] = useState(false);
	const [displayOutsideHours, setDisplayOutsideHours] = useState(null);
	const [visible, setVisible] = useState(false);
	const [chartData, setChartData] = useState({});
	const [toolDropCancelId, setTooldropCancelId] = useState(null);
	const [isCancelDialogVisible, setIsCancelDialogVisible] = useState(false);
	const [tooldropEdit, setTooldropEdit] = useState(null);
	const [tenantName, setTenantName] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [leaseStart, setLeaseStart] = useState(new Date());
	const [saveArray, setSaveArray] = useState([]);

	const global = useContext(ConcessionsContext);
	const user = useContext(UserContext);
	let tenant = user.tenant;

	useEffect(() => {
		if(tenant === null || tenant === undefined || tenant === "") {
			return;
		}

		user.apiCall(`tenants/${tenant}`)
			.then(res => {
				setTenantName(res.data[0].name);
				setIsActive(res.data[0].isActive);
				setLeaseStart(new Date(res.data[0].leaseStart));
			})

		fetchToolDrops();

		if (promptUser) {
			window.onbeforeunload = () => true
		}
		else {
			window.onbeforeunload = undefined
		}
	}, [tenant]);

	useEffect(() => {
		if (user && tenant && records?.length < 1 && recordData?.length > 0) {
			updateRecords();
		}
	}, [recordData, user]);

	useEffect(() => {
		user.apiCall(`tenants/${tenant}/concessionRecords`)
			.then(res => {
				setRecordData(...res.data);
				updateRecords(res.data);
			});
	}, [leaseStart])

	const updateSaveArray = (newRecord) => {
		let tempArray = [...saveArray];
		var index = tempArray.map((e) => e.transactionsDate).indexOf(newRecord.transactionsDate);

		if (index != -1) {
			tempArray[index] = newRecord;
		}
		else {
			tempArray.push(newRecord);
		}
		setSaveArray(tempArray);
	}

	const updateRecords = (conRecords) => {
		const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		let weeklyRecords = conRecords || recordData;
		let idx = 0;
		let dayArray = [];
		let sumMon = 0;
		let sumTran = 0;
		let chartLabels = [];
		let transactionData = [];
		let monetaryData = [];
		const now = new Date();
		let prevDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 12, 0, 0));

		do {
			let newDayEntry = {
				lastModified: new Date(),
				lastModifiedByEmail: user?.username ?? "",
				tenantId: tenant,
				transactionsDate: prevDay,
				monetaryAmount: 0,
				numTransactions: 0,
				dayOfWeek: weekday[prevDay.getDay()]
			}

			// TODO: Check if records are sorted
			if(weeklyRecords.length > idx) {
				const trDt = new Date(weeklyRecords[idx].transactionsDate);
				if (format(trDt, "yyyy-MM-dd") === format(prevDay, "yyyy-MM-dd")) {
					newDayEntry = {
						...weeklyRecords[idx],
						transactionsDate: new Date(weeklyRecords[idx].transactionsDate),
						lastModified: new Date(weeklyRecords[idx].lastModified),
						dayOfWeek: weekday[prevDay.getDay()]
					}

					sumMon = sumMon + weeklyRecords[idx].monetaryAmount;
					sumTran = sumTran + weeklyRecords[idx].numTransactions;
					idx++;
				}
			}

			dayArray.push(newDayEntry);
			chartLabels.unshift(format(prevDay, "MM/dd"));
			transactionData.unshift(newDayEntry.numTransactions);
			monetaryData.unshift(newDayEntry.monetaryAmount);
			prevDay = addDays(prevDay, -1);

			// TODO: tenant is a GUID. Get lease start from somewhere
		} while(dayArray.length < 60 && prevDay > leaseStart)

		setRecords(dayArray);
		setSumMonetrary(sumMon);
		setSumTransaction(sumTran);
		setChartData({
			labels: chartLabels,
			datasets: [
				{
					label: 'Monetary Amount',
					data: monetaryData,
					fill: false,
					borderColor: '#42A5F5',
					yAxisID: 'y',
					tension: .4
				},
				{
					label: 'Transaction Amount',
					data: transactionData,
					fill: false,
					borderColor: '#FFA726',
					yAxisID: 'y1',
					tension: .4
				}
			]
		})
	}

	const setMonetary = (rowData, value) => {
		rowData.monetaryAmount = (value === null || value === undefined || value === '' ? 0 : parseFloat(value))
		rowData.lastModified = new Date();
		rowData.tenantId = tenant;
		rowData.lastModifiedByEmail = user.username;

		if (rowData.monetaryAmount > 0 && rowData.numTransactions === 0) {
			setPromptUserPartial(true)
		}
		else {
			setPromptUserPartial(false)
		}
		var index = records.map((e) => e.transactionsDate).indexOf(rowData.transactionsDate);
		let data = [...records];
		data[index] = rowData;

		setRecords(data);
		updateSaveArray(rowData);
		setPromptUser(true);
	}

	const setTransactions = (rowData, value) => {
		rowData.numTransactions = (value === null || value === undefined || value === '' ? 0 : parseInt(value));
		rowData.lastModified = new Date();
		rowData.tenantId = tenant;
		rowData.lastModifiedByEmail = user.username;
		if (rowData.numTransactions > 0 && rowData.monetaryAmount === 0) {
			setPromptUserPartial(true);
		}
		else {
			setPromptUserPartial(false);
		}
		var index = records.map((e) => e.transactionsDate).indexOf(rowData.transactionsDate);
		let data = [...records];
		data[index] = rowData;

		updateSaveArray(rowData);
		setRecords(data);
		setPromptUser(true);
	}

	const saveAction = () => {
		user.apiCall('concessionRecords', saveArray, 'POST')
			.then((res) => {
				if (res.status === 200) {
					global.successToast(transactionMsg);
					setSaveArray([]);
				}
				else {
					global.errorToast(transactionFail);
				}

				let sumMon = 0;
				let sumTran = 0;
				for (let record of records) {
					sumMon = sumMon + record.monetaryAmount;
					sumTran = sumTran + record.numTransactions;
				}

				setSumMonetrary(sumMon);
				setSumTransaction(sumTran);
				setPromptUser(false);
				setPromptUserPartial(false);
				setVisible(false);

				user.apiCall(`tenants/${tenant}/concessionRecords`)
					.then(res => {
						setRecordData(...res.data);
						updateRecords(res.data);
					});
			});
	}

	const fetchToolDrops = () => {
		if(tenant === null || tenant === undefined || tenant === "") {
			return;
		}
		user.apiCall(`tooldroptenant/${tenant}`)
			.then((res) => {
				setToolDrops(res.data)
			});
	}

	const handleToolDropSubmit = (res) => {
		setTooldropEdit(null)
		if(res === "FAIL") {
			global.errorToast(tooldropFail);
		}
		else {
			global.successToast(tooldropMsg);
			setToolDropDialogOpen(false);
			fetchToolDrops();
		}
	}

	const formatDate = (value) => {
		return value.toLocaleDateString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
	}

	const toolDropHeader = () => {
		const message = "Submit a maintenance request for this tenant.\n" +
			"If the company fulfilling the request cannot be found in the dropdown, click the checkbox to enter the name manually.\n" +
			"Airport logistics employees will be able to view any submitted requests."
		return (
			<>
				<p style={{ display: "inline-block", margin: "0", verticalAlign: "top" }}>
					{tenantName + " Tool Drop Request"}
				</p>
				<Tooltip target=".custom-target-icon" style={{ maxWidth: "30vw" }} />
				<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
					data-pr-tooltip={message}
					data-pr-position="right"
					data-pr-at="right+5 center"
					data-pr-my="left center-2"
					style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', margin: "2px 0 0 8px" }}>
				</i>
			</>
		);
	}

	const monetaryBodyTemplate = (rowData) => (
		<InputNumber disabled={!isActive}
			value={rowData.monetaryAmount}
			onChange={(e) => setMonetary(rowData, e.value)}
			mode="decimal"
			locale="en-US"
			maxFractionDigits={2}
		/>
	);

	const transactionsBodyTemplate = (rowData) => (
		<InputText disabled={!isActive}
			value={rowData.numTransactions}
			onChange={(e) => setTransactions(rowData, e.target.value)}
		/>
	);

	const dateBodyTemplate = (rowData) => {
		return rowData.dayOfWeek + " " + formatDate(rowData.transactionsDate);
	}

	const acceptCancel = (e) => {
		user.apiCall('tooldrop/' + toolDropCancelId + '/cancel', {}, 'PUT')
			.then((res) => {
				user.apiCall(`tooldroptenant/${tenant}`)
					.then((res) => {
						setToolDrops(res.data)
						setTooldropCancelId(null)
						setIsCancelDialogVisible(false)
					})
			})
	}

	const rejectCancel = () => {
		setTooldropCancelId(null);
		setIsCancelDialogVisible(true);
	}

	const editTooldrop = (rowData) => {
		setTooldropEdit({
			...rowData,
			vendorNameManual: rowData.vendorName
		})
		setToolDropDialogOpen(true)
	}

	const cancelButton = (rowData) => {
		if (rowData.isCancelled) {
			return 'Cancelled';
		}
		return (
			<span>
				<Button style={{ margin: '2px' }}
					label="Edit"
					icon="pi pi-pencil"
					onClick={() => editTooldrop(rowData)}
				/>
				<Button style={{ margin: '2px' }}
					label="Cancel"
					icon="pi pi-times"
					className="p-button-danger"
					onClick={(e) => {
						setIsCancelDialogVisible(true);
						setTooldropCancelId(rowData.id);
					}}
				/>
			</span>
		);
	}

	return (
		<div className="p-grid">
			<Prompt when={promptUser}
				message='You have unsaved changes, are you sure you want to leave?'
			/>
			<div className="p-col-12">
				<div className="card">
					<div style={{display: 'inline-block', width: '100%'}}>
						<h5 style={{ display: 'inline-block' }}>Record Entry</h5>
						<Button label="Submit"
							onClick={() => {
								promptUserPartial ? setVisible(true) : saveAction()
							}}
							aria-label="Submit"
							style={{ float: 'right', marginTop: '-8px' }}
						/>
					</div>
					<div>
						<div id="recordTable" style={{ width: '40%', display: 'inline-block', verticalAlign: "top" }}>
							<DataTable value={records}
								rows={10}
								sortField="transactionDate"
								sortOrder="1"
								responsiveLayout="scroll"
								showGridlines
								paginator
							>
								<Column alignHeader='center'
									align='center'
									field="transactionsDate"
									header="Date"
									body={dateBodyTemplate}
									footer={`Last ${records.length} Days`}
								/>
								<Column alignHeader='center'
									align='center'
									field="monetaryAmount"
									footer={'$' + sumMonetrary.toFixed(2)}
									header="Total Sales"
									body={monetaryBodyTemplate}
								/>
								<Column alignHeader='center'
									align='center'
									field="numTransactions"
									footer={sumTransaction}
									header="Total Transactions"
									body={transactionsBodyTemplate}
								/>
							</DataTable>
							<ConfirmDialog visible={visible}
								onHide={() => setVisible(false)}
								message="Some transactions only have partial data. Do you want to save?"
								header="Confirmation"
								icon="pi pi-exclamation-triangle"
								accept={saveAction}
								reject={() => setVisible(false)}
							/>
						</div>
						<div id="recordChart" style={{ display: 'inline-block', width: '55%', marginLeft: '3%', paddingTop: '5%', minHeight: '800px' }} >
							<Chart type="line"
								data={chartData}
								options={multiAxisOptions}
							/>
						</div>
					</div>
					<div style={{width: "50%", display: "inline-block", verticalAlign: "bottom"}}>
						<h5>Tool Drop Requests</h5>
					</div>
					<div style={{ width: "50%", paddingTop: "12px", display: "inline-block" }}>
						<Button label="History"
							icon="pi pi-external-link"
							onClick={() => setDisplayHistory(true)}
						/>
						<Button label="ToolDrop Request"
							icon="pi pi-plus"
							className='p-button-warning'
							onClick={() => setToolDropDialogOpen(true)}
							style={{float: "right", margin: "4px 0"}}
						/>
					</div>
					<Dialog header="Request History"
						visible={displayHistory}
						style={{ width: '70vw' }}
						onHide={() => setDisplayHistory(false)}
					>
						<ToolDropHistory />
					</Dialog>
					<Dialog header="Outside of Standarad Hours"
						visible={displayOutsideHours != null}
						style={{ width: '70vw' }}
						onHide={() => setDisplayOutsideHours(false)}
					>
						{displayOutsideHours === 3 ?
							<p>These hours are outside of the standard tool drop hours, please contact Bradford Logistics at 412 472 5440</p>
							: displayOutsideHours === 4
								? <p>These hours are outside of the standard tool drop hours, please contact Airport Operations at 412 572 5630</p>
								: null}
					</Dialog>
					<div>
						<DataTable value={toolDrops}
							rows="5"
							paginator
						>
							<Column field="vendorName"
								header="Vendor Name"
								align="center"
								alignHeader='center'
							/>
							<Column field="serviceDate"
								header="Service Date"
								body={(d) => dateFromUtcTemplate(d, "serviceDate")}
								align="center"
								alignHeader='center'
							/>
							<Column field="dropOffDate"
								header="Drop Off Date"
								body={(d) => dateFromUtcTemplate(d, "dropOffDate")}
								align="center"
								alignHeader='center'
							/>
							<Column field="repairCategory"
								header="Repair Category"
								align="center"
								alignHeader='center'
							/>
							<Column field="salesImpact"
								header="Impact on Sales"
								align="center"
								alignHeader='center'
							/>
							<Column field="email"
								header="Email"
								align="center"
								alignHeader="center"
							/>
							<Column field="requestDate"
								header="Request Date"
								body={(d) => dateOnlyTemplate(d, "requestDate")}
								align="center"
								alignHeader="center"
							/>
							<Column field='id' body={cancelButton} />
						</DataTable>
					</div>
				</div>
			</div>
			<ConfirmDialog header={"Cancelation Confirmation"}
				visible={isCancelDialogVisible}
				onHide={() => setIsCancelDialogVisible(false)}
				message={"Are you sure you wish to cancel this request?"}
				icon="pi pi-exclamation-triangle"
				accept={acceptCancel}
				reject={rejectCancel}
			/>
			<Dialog header={toolDropHeader}
				visible={toolDropDialogOpen}
				onHide={() => {
					setToolDropDialogOpen(false);
					setTooldropEdit(null);
				}}
				draggable={false}
				dismissableMask
			>
				<ToolDropForm values={{ tenantId: tenant ?? "", createdBy: user?.username ?? "" }}
					onSubmit={(res) => handleToolDropSubmit(res)}
					outsideHours={setDisplayOutsideHours}
					edit={tooldropEdit}
				/>
			</Dialog>
		</div>
	);
}
