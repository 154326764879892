import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import { UserContextProvider } from "./UserContext";
import { ConcessionsContextProvider } from './service/ConcessionsContext';
import App from './App';
import { Error } from './pages/Error';

const AppWrapper = () => {

	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location]);

	switch (location.pathname) {
	case '/login':
		return null;
	case '/error':
		return <Route path="/error" component={Error} />
	default:
		return (
			<UserContextProvider>
				<ConcessionsContextProvider><App /></ConcessionsContextProvider>
			</UserContextProvider>
		);
	}
}

export default withRouter(AppWrapper);
