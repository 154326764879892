import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { ConcessionsContext } from '../service/ConcessionsContext';

export const Hours = ({
    user,
    changeObject,
    curTenant
}) => {
    const [unitTenantHours, setUnitTenantHours] = useState([]);
    const [updatedTable, setUpdatedTable] = useState([]);
    const [changedRows, setChangedRows] = useState([]);
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false); // State for delete confirmation dialog
    const [deleteRow, setDeleteRow] = useState(null); // State to track the row to be deleted
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [invalidTime, setInvalidTime] = useState(false);

    const global = useContext(ConcessionsContext);

    const saveSuccessMessage = "Updated Successfully";
    const saveFailMessage = "Failed to update";

    useEffect(() => {
        if (curTenant?.tenantId) {
            user.apiCall(`tenants/${curTenant?.tenantId}`)
                .then(res => {
                    user.apiCall(`unittenanthours/${res.data[0].unitToTenantId}?month=${encodeURIComponent(String(date.getMonth() + 1))}&year=${encodeURIComponent(String(date.getFullYear()))}`)
                        .then(res => {
                            setUnitTenantHours(res.data);
                            setLoading(false)
                        })

                });
        }
    }, [date]);

    useEffect(() => {
        if (curTenant?.tenantId) {
            const currentMonthDates = getAllDatesInMonth();
            const updatedUnitTenantHours = currentMonthDates.map(date => {
                const unitTenantHourForDate = unitTenantHours.find(unitT => {
                    const unitToDate = unitT.unitToTenantDate.split('T')[0];
                    const currentDate = date.toISOString().split('T')[0];
                    return unitToDate === currentDate;
                });

                const dayOfWeek = getDayOfWeek(date);
                return unitTenantHourForDate ? {
                    ...unitTenantHourForDate,
                    unitToTenantDate: unitTenantHourForDate.unitToTenantDate.split('T')[0],
                    dayOfWeek,
                    startTime: unitTenantHourForDate.startTime ? unitTenantHourForDate.startTime.slice(0, 5) : unitTenantHourForDate.startTime,
                    endTime: unitTenantHourForDate.endTime ? unitTenantHourForDate.endTime.slice(0, 5) : unitTenantHourForDate.endTime
                } : {
                    unitToTenantDate: date.toISOString().split('T')[0],
                    startTime: null,
                    endTime: null,
                    dayOfWeek,
                    unitTenantHoursId: null,
                    unitToTenantId: changeObject?.unitToTenantId
                };
            });
            setUpdatedTable(updatedUnitTenantHours);
        }
    }, [unitTenantHours]);

    const getDayOfWeek = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    };

    const getAllDatesInMonth = () => {
        const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        const dates = [];
        for (let i = 1; i <= daysInMonth; i++) {
            dates.push(new Date(date.getFullYear(), date.getMonth(), i));
        }
        return dates;
    };

    const cellEditor = (options) => {
        const { value, editorCallback } = options;

        const handleChange = (e) => {
            let val = e.target.value;
            // Automatically add ':' after two digits
            if (val.length === 2 && val.indexOf(':') === -1) {
                val += ':';
            }
            editorCallback(val);
        };

        return (
            <InputText
                type="text"
                value={value || ''} // Ensure value is not null
                onChange={handleChange}
                placeholder="Enter time (hh:mm)"
                autoFocus
            />
        );
    };

    const validTime = (time) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(time);
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        const existingValue = rowData[field];

        if (newValue !== existingValue && validTime(newValue)) {
            if (newValue !== existingValue && validTime(newValue)) {
                switch (field) {
                    case 'endTime':
                        if (newValue < rowData['startTime']) {
                            setInvalidTime(true);
                            return;
                        }
                        break;

                }
            }
            rowData[field] = newValue;
            setUpdatedTable([...updatedTable]);
            // Add changed row to the list
            const changedRow = {
                ...rowData,
                [field]: newValue // Update only the field that has been changed
            };
            setChangedRows(prevRows => [...prevRows, changedRow]);
        } else {
            event.preventDefault();
        }
    };

    const handleSave = () => {
        setDisplayConfirmDialog(true);
    };

    const onSaveConfirm = () => {
        const uniqueDates = [];
        const uniqueChangedRows = [];

        changedRows.forEach(row => {
            const index = uniqueDates.findIndex(date => date === row.unitToTenantDate);
            if (index === -1) {
                uniqueDates.push(row.unitToTenantDate);
                uniqueChangedRows.push(row);
            } else {
                uniqueChangedRows[index] = row; // Update the existing entry with the latest change
            }
        });
        if (uniqueChangedRows) {
            user.apiCall('unittenanthours', uniqueChangedRows, 'POST')
                .then(res => {
                    if (res.status == 200) {
                        global.successToast(saveSuccessMessage);
                    } else {
                        global.errorToast(saveFailMessage);
                    }
                    setChangedRows([]);
                }).catch(error => {
                    global.errorToast('Failed to save changes. Check Data');
                });
        }
        setDisplayConfirmDialog(false);
    };

    const onSaveReject = () => {
        setDisplayConfirmDialog(false);
    };

    const onDelete = (rowData) => {
        setDeleteRow(rowData); // Set the row to be deleted
        setDisplayDeleteDialog(true); // Show delete confirmation dialog
    };


    const onDeleteConfirm = () => {
        const unitTenantHoursId = deleteRow.unitTenantHoursId; // Assuming unitTenantHoursId is the identifier for deletion

        user.apiCall(`unittenanthours/${unitTenantHoursId}`, null, 'DELETE')
            .then(res => {
                if (res.status === 200) {
                    global.successToast('Deleted Successfully');
                    // Remove the deleted row from updatedTable state
                    if (curTenant?.tenantId) {
                        user.apiCall(`tenants/${curTenant?.tenantId}`)
                            .then(res => {
                                user.apiCall(`unittenanthours/${res.data[0].unitToTenantId}?month=${encodeURIComponent(String(date.getMonth() + 1))}&year=${encodeURIComponent(String(date.getFullYear()))}`)
                                    .then(res => {
                                        setUnitTenantHours(res.data);
                                    })
            
                            });
                    }
                    setChangedRows(prevRows => prevRows.filter(row => row.unitTenantHoursId !== unitTenantHoursId));
                } else {
                    global.errorToast('Failed to delete');
                }
            })
            .catch(error => {
                console.error('Error deleting record:', error);
                global.errorToast('Failed to delete');
            });
    
        setDeleteRow(null); // Reset delete row state
        setDisplayDeleteDialog(false); // Close delete confirmation dialog
    };
    

    const onDeleteReject = () => {
        setDeleteRow(null); // Reset delete row state
        setDisplayDeleteDialog(false); // Close delete confirmation dialog
    };

    return (
        <div id="Hours">
            <div className="card">
                <div className='grid'>
                    {[
                        { label: 'Name', id: 'name', value: changeObject?.name },
                        { label: 'Unit', id: 'unit', value: changeObject?.unitName },
                        { label: 'Tenant Type', id: 'tenantType', value: changeObject?.tenantTypeName }
                    ].map((field, index) => (
                        <div className='col' key={index}>
                            <div className="p-field">
                                <span className="p-float-label">
                                    <InputText id={field.id} value={field.value} className='p-float-label-input-text' disabled />
                                    <label htmlFor={field.id}>{field.label}</label>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="grid">
                    <div className="col">
                        <div className="p-field">
                            <label htmlFor="monthyear">Select Month and Year</label>
                            <div className='col-3' style={{ display: 'flex'}}>
                                <Calendar
                                    id="monthyear"
                                    onChange={(e) => setDate(e.value)}
                                    view="month"
                                    value={date}
                                    dateFormat='mm/yy'
                                    style={{ marginRight: '1rem' }}
                                />
                                <Button label="Save" onClick={handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog visible={displayConfirmDialog} onHide={() => setDisplayConfirmDialog(false)} header="Confirm">
                    <div>Do you want to save changes?</div>
                    <div className="p-dialog-footer">
                        <Button label="Save" onClick={onSaveConfirm} />
                        <Button label="Cancel" onClick={onSaveReject} />
                    </div>
                </Dialog>
                <Dialog visible={invalidTime} onHide={() => setInvalidTime(false)} header="Error">
                    <div>End time cannot be more than Start Time</div>
                </Dialog>
                <Dialog visible={displayDeleteDialog} onHide={() => setDisplayDeleteDialog(false)} header="Confirm Delete">
                    <div>Do you want to delete this row?</div>
                    <div className="p-dialog-footer">
                        <Button label="Yes" onClick={onDeleteConfirm} />
                        <Button label="No" onClick={onDeleteReject} />
                    </div>
                </Dialog>
                <DataTable loading={loading} value={updatedTable} rowClassName={rowData => changedRows.some(row => row.unitToTenantDate === rowData.unitToTenantDate) ? 'changed-row' : ''} showGridlines>
                    <Column field="unitToTenantDate" header="Date" />
                    <Column field="dayOfWeek" header="Day of Week" />
                    <Column field="startTime" header="Open" editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="endTime" header="Closed" editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column headerStyle={{ width: '6rem' }} body={(rowData) => (
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => onDelete(rowData)} />
                    )} />
                </DataTable>
            </div>
        </div>
    );
};
