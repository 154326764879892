import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";
import { ConcessionsContext } from '../service/ConcessionsContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';

const sectionMsg = "Section added.";
const sectionFail = "Failed to add section to database.";
const sectionDeleteMsg = "Section removed from tenant.";
const sectionDeleteFail = "Failed to remove section.";

export const Sections = () => {
	const [sections, setSections] = useState([]);
	const [sectionToDelete, setSectionToDelete] = useState();
	const [sectionToDeleteId, setSectionToDeleteId] = useState();
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [newSection, setNewSection] = useState('');
	const [sectionNameError, setSectionNameError] = useState(false)

	const user = useContext(UserContext);
	const global = useContext(ConcessionsContext);

	useEffect(() => {
		user.apiCall('sections')
			.then(res => {
				let sectionAry = []
				let resultAry = []
				for (let i = 0; i < res.data.length; i++) {
					if (!sectionAry.includes(res.data[i].sectionId)) {
						sectionAry.push(res.data[i].sectionId)
						resultAry.push({ sectionId: res.data[i].sectionId, sectionName: res.data[i].sectionName, locations: res.data[i].l })
					}
					else {
						resultAry[sectionAry.indexOf(res.data[i].sectionId)].locations = res.data[i].l;
					}
				}

				setSections(resultAry);
			})
	}, []);

	const buttonBodyTemplate = (rowData) => {
		if (rowData.sectionId) {
			return (
				<Button tooltip="Delete Section"
					tooltipOptions={{ position: 'left' }}
					onClick={() => {
						setSectionToDeleteId(rowData.sectionId); setSectionToDelete(rowData.sectionName); setDisplayDeleteDialog(true)
					}}
					label={<i className="pi pi pi-times"></i>}
					className="p-button-rounded p-button-primary" />
			)
		}
	}

	const addSection = () => {
		const body = {
			SectionName: newSection.trim(),
		}
		if(newSection.trim().length == "0"){
			setSectionNameError(true)
			return;
		}


		user.apiCall('sections', body, 'POST')
			.then((res) => {
				if (res.status === 200) {
					global.succesToast(sectionMsg);
					user.apiCall('sections').then(res => {
						let sectionAry = []
						let resultAry = []
						for (let i = 0; i < res.data.length; i++) {
							if (!sectionAry.includes(res.data[i].sectionId)) {
								sectionAry.push(res.data[i].sectionId)
								resultAry.push({ sectionId: res.data[i].sectionId, sectionName: res.data[i].sectionName, locations: res.data[i].l })
							}
							else {
								resultAry[sectionAry.indexOf(res.data[i].sectionId)].locations = res.data[i].l
							}
						}
						setSections(resultAry);
						setDisplayModal(false);
						setNewSection('')
					})
				}
				else {
					global.errorToast(sectionFail);
				}
			}).catch(err=>global.errorToast("Failed. Invalid Section Name or Section Already Exists"));
	}

	const updateSection = (props) => {
		const body = {
			SectionId: props.newData.sectionId,
			SectionName: props.newData.sectionName.trim()
		}
		user.apiCall('sections/' + props.newData.sectionId, body, 'PUT')
			.then((res) => {
				if (res.status === 200) {
					global.succesToast(sectionMsg);
					user.apiCall('sections').then(res => {
						let sectionAry = []
						let resultAry = []
						for (let i = 0; i < res.data.length; i++) {
							if (!sectionAry.includes(res.data[i].sectionId)) {
								sectionAry.push(res.data[i].sectionId)
								resultAry.push({ sectionId: res.data[i].sectionId, sectionName: res.data[i].sectionName, locations: res.data[i].l })
							}
							else {
								resultAry[sectionAry.indexOf(res.data[i].sectionId)].locations = res.data[i].l
							}
						}
						setSections(resultAry);
					})
				}
				else {
					global.errorToast(sectionFail);
				}
			}).catch(err=>global.errorToast("Update Failed"));
	}

	const deleteSection = () => {
		user.apiCall('sections/' + sectionToDeleteId, {}, 'DELETE')
			.then((res) => {
				if (res.status === 200) {
					global.succesToast(sectionDeleteMsg);
					user.apiCall('sections').then(res => {
						let sectionAry = []
						let resultAry = []
						for (let i = 0; i < res.data.length; i++) {
							if (!sectionAry.includes(res.data[i].sectionId)) {
								sectionAry.push(res.data[i].sectionId)
								resultAry.push({ sectionId: res.data[i].sectionId, sectionName: res.data[i].sectionName, locations: res.data[i].l })
							}
							else {
								resultAry[sectionAry.indexOf(res.data[i].sectionId)].locations = res.data[i].l
							}
						}
						setSections(resultAry);
					})
				}
				else {
					global.errorToast(sectionDeleteFail);
				}
			}).catch((err)=>global.errorToast("Failed to delete"))
	}

	const locationBodyTemplate = (rowData) => {
		let formattedLocations = '';
		for (let i = 0; i < rowData.locations.length; i++) {
			formattedLocations += rowData.locations[i]+'\n'
		}
		return (
			<div>
				{rowData.locations.length > 0 && (
					<Tooltip target={'.tooltip' + rowData.sectionId} content={formattedLocations} />
				)}
				<p className={'tooltip' + rowData.sectionId}>{rowData.locations.length}</p>
			</div>
		);
	}

	const inputTextEditor = (props, field) => (
		<InputText type="text"
			value={props.rowData[field]}
			onChange={(e) => props.editorCallback(e.target.value)}
		/>
	)

	return (
		<div className="p-grid">
			<Dialog style={{ minWidth: '40vw' }}
				header="Add New Section"
				visible={displayModal}
				onHide={() => setDisplayModal(false)}
				footer={
					<Button label="Save"
						icon="pi pi-check"
						onClick={() => addSection()}
						autoFocus
					/>
				}
			>
				<div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    Section Name: <InputText value={newSection} onChange={(e) => {setNewSection(e.target.value); if(!(e.value == ""))setSectionNameError(false)}} 
					style = {{borderColor : sectionNameError?'red':'#ced4da'}}/>
					{sectionNameError && <div style={{color:'red'}}>Section name cannot be empty</div>}
				</div>
			</Dialog>
			<ConfirmDialog visible={displayDeleteDialog}
				onHide={() => setDisplayDeleteDialog(false)}
				message={"Do you want to delete " + sectionToDelete + "?"}
				header="Delete Confirmation"
				icon="pi pi pi-info-circle"
				accept={() => deleteSection()}
				reject={null}
			/>
			<div className="p-col-12">
				<div className="card">
					<h5>Sections</h5>
					<div>
						<DataTable value={sections}
							editMode="row"
							onRowEditComplete={(props) => updateSection(props)}
							filterDisplay="row"
							sortField="email"
							sortOrder="1"
							responsiveLayout="scroll" >
							<Column align='center'
								alignHeader='center'
								field="sectionName"
								filter
								filterPlaceholder="Search by name"
								filterMatchMode="contains"
								showFilterMenu={false}
								editor={(props) => inputTextEditor(props, 'sectionName')}
								sortable
								header="Section Name" >
							</Column>
							<Column align='center'
								alignHeader='center'
								field="locations.length"
								body={locationBodyTemplate}
								sortable
								header="Locations"
								style={{ width: '10%' }} >
							</Column>
							<Column align='center'
								rowEditor
								headerStyle={{ width: '10%', minWidth: '8rem' }}
								bodyStyle={{ textAlign: 'center' }}>
							</Column>
							<Column align='center'
								alignHeader='center'
								style={{ width: '10%' }}
								field="buttons"
								body={buttonBodyTemplate}
								header={<Button tooltip="Add Section"
									tooltipOptions={{ position: 'left' }}
									style={{ backgroundColor: "white", color: "#537dbf" }}
									onClick={() => setDisplayModal(true)}
									label={<i className="pi pi-plus"></i>}
									className="p-button-rounded p-button-primary" />}>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	);
}
