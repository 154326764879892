import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../UserContext";

export const TenantMetadata = (props) => {
	const [tenant, setTenant] = useState({});
	const user = useContext(UserContext);

	useEffect(() => {
		if (user.tenant === null || user.tenant === undefined || user.tenant === "") {
			return;
		}

		user.apiCall('tenants/' + user.tenant).then(res => {
			setTenant(res.data[0]);
		})
	}, [user.tenant]);

	return (
		<div className='p-col-12 p-grid'
			style={{ marginBottom: '5px' }}
		>
			<b>{tenant?.name}</b>&nbsp;|&nbsp;
			<b>{tenant?.tenantTypeName}</b>&nbsp;|&nbsp;
			<b>{tenant?.unitName}</b>&nbsp;|&nbsp;
			<b>{tenant?.sqft}sqft</b>&nbsp;|&nbsp;
			<b>{tenant?.locationName}</b>&nbsp;|&nbsp;
			<b>{tenant?.sectionName}</b>
		</div>
	);
}
