import React, { createContext, useRef } from "react";
import { Toast } from "primereact/toast";

const ConcessionsContext = createContext();

const ConcessionsContextProvider = ({ children }) => {
	const toastRef = useRef(null);

	const successToast = (msg, sticky = false) => {
		let params = {};
		if(!sticky) {
			params.life = 4000;
		}
		else {
			params.sticky = true;
		}

		toastRef.current.show({
			severity: "success",
			summary: "Success",
			detail: msg,
			...params
		})
	}

	const errorToast = (msg, sticky = false) => {
		let params = {};
		if(!sticky) {
			params.life = 4000;
		}
		else {
			params.sticky = true;
		}

		toastRef.current.show({
			severity: "error",
			summary: "Error",
			detail: msg,
			...params
		})
	}

	return (
		<ConcessionsContext.Provider value={{successToast, errorToast}}>
			<Toast ref={toastRef} />
			{children}
		</ConcessionsContext.Provider>
	)
}

export { ConcessionsContext, ConcessionsContextProvider };