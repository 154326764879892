import React, { useEffect, useState,useContext } from 'react'
import { UserContext } from "../UserContext";
import { Form, Field } from 'react-final-form';
import { formatInTimeZone } from 'date-fns-tz';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { Tooltip } from 'primereact/tooltip';
import "./ToolDropForm.css"

const strFormat = "yyyy-MM-dd HH:mm:ss";

/*
    props:
    values {}
    onSubmit (success)
*/
export const ToolDropForm = (props) => {
	const [repairCatOptions, setRepairCatOptions] = useState([]);
	const [impactOptions, setImpactOptions] = useState([]);
	const [vendorOptions, setVendorOptions] = useState([]);
	const [isManualVendor, setIsManualVendor] = useState();

	const user = useContext(UserContext)

	useEffect(() => {
		user.apiCall(`tooldropformoptions`)
			.then((res) => {
				setRepairCatOptions(res.data.repairCatList);
				setImpactOptions(res.data.impactList);
				setVendorOptions(res.data.vendorList);

				if(props.edit !== null) {
					const isManual = res.data.vendorList.find((elem) => elem.value === props.edit.vendorName);
					if(isManual === undefined) {
						setIsManualVendor(true);
					}
				}
			})
	}, [])

	const handleSubmit = (formData) => {
		if(isManualVendor && formData.vendorNameManual && formData.vendorNameManual !== "") {
			formData.vendorName = formData.vendorNameManual;
		}
		formData.requestDate = new Date()
		if (props.edit != null) {
			user.apiCall(`tooldrop/` + props.edit.id, formData, "PUT")
				.then((res) => {
					props.onSubmit("");
				})
				.catch((res) => {
					props.onSubmit("FAIL");
				})
		}
		else {
			user.apiCall(`tooldrop`, formData, "POST")
				.then((res) => {
					props.onSubmit("");
				})
				.catch((res) => {
					props.onSubmit("FAIL");
				})
		}
	}

	const validate = (formData) => {
		let errors = {};

		if(!formData.email || formData.email === "") {
			errors.email = "Email required";
		}
		if(!formData.phoneNumber || formData.phoneNumber === "") {
			errors.phoneNumber = "Phone Number required";
		}
		if(!formData.repairCategory || formData.repairCategory === "") {
			errors.repairCategory = "Repair Category required";
		}
		if(!formData.salesImpact || formData.salesImpact === "") {
			errors.salesImpact = "Sales Impact required";
		}
		if(!formData.tenantId || formData.tenantId === "") {
			errors.tenantId = "Tenant required";
		}

		if(!formData.serviceDate || formData.serviceDate === "") {
			errors.serviceDate = "Service Date required";
		}
		else if(!formData.dropOffDate || formData.dropOffDate === "") {
			errors.dropOffDate = "Drop Off Date required";
		}
		else if(new Date(formData.serviceDate) < new Date(formData.dropOffDate)) {
			errors.serviceDate = "Service Date must be after Drop Off Date";
		}

		if(!isManualVendor && !formData.vendorName || formData.vendorName === ""){
			errors.vendorName = "Vendor Name required";
		}
		else if(isManualVendor && !formData.vendorNameManual || formData.vendorNameManual === ""){
			errors.vendorNameManual = "Vendor Name required";
		}

		return errors;
	}

	const getFormErrorMessage = (meta) => {
		return !!(meta.touched && meta.error) && <small className="p-error">{meta.error}</small>;
	};

	return (
		<span >
			<span style={{ display: 'inline-block',width: '700px', textAlign: 'center' }}>
				All tool drops require at least 24-hour notice.  Standard hours for tool drops are 4:00 AM to 10:00 AM Monday through Friday.
				If you have a tool drop that is between 10:00 AM and 3:00 PM Monday through Friday, please contact Bradford Logistics directly at (412)-472-5440.
				After-hour submissions will not be serviced the next morning but rather the following day.
				If you have an EMERGENCY, submit this request and then contact Airport Operations at (412) 472-5630.
			</span>
			<Form
				onSubmit={handleSubmit}
				validate={validate}
				initialValues={props.edit === null ? props.values : props.edit}
				render={({handleSubmit, values}) => {
					return (
						<form onSubmit={handleSubmit} className="master-form">
							<Field name="email"
								render={({ input, meta }) => (
									<div style={{ display: "inline-block",width: '100%' }} >
										<span style={{ width: '85%', display: "inline-block" } }>
											<span className="p-float-label">
												<InputText {...input}
													id="email"
												/>
												<label htmlFor='email'>Email:&nbsp;</label>
												<Tooltip target=".custom-target-icon" />
											</span>
										</span>
										<span style={{ width: '15%', display: "inline-block" } }>
											<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
												data-pr-tooltip="Concession Requestor Email Address"
												data-pr-position="right"
												data-pr-at="right+5 center"
												data-pr-my="left center-2"
												style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', marginLeft: "10px" }}>
											</i>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<Field name="phoneNumber"
								render={({ input, meta }) => (
									<div style={{ display: "inline-block", width: '100%' }} >
										<span style={{ width: '85%', display: "inline-block" } }>
											<span className="p-float-label">

												<InputMask {...input}
													id="phoneNumber"
													mask="(999) 999-9999" />
												<label htmlFor='phoneNumber'>Phone Number:&nbsp;</label>
												<Tooltip target=".custom-target-icon" />
											</span>
										</span>
										<span style={{ width: '15%', display: "inline-block" } }>
											<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
												data-pr-tooltip="Concession Requestor Telephone Number"
												data-pr-position="right"
												data-pr-at="right+5 center"
												data-pr-my="left center-2"
												style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', marginLeft: "10px" }}>
											</i>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<Field name="dropOffDate"
								render={({ input, meta }) => (
									<div style={{ display: "inline-block", width: '100%' }} >
										<span style={{ width: '85%', display: "inline-block" } }>
											<span className="p-float-label">
												<Calendar value={input.value !== "" ? new Date(input.value + "Z") : ""}
													id="dropOffDate"
													onChange={(e) => {
														const formatted = formatInTimeZone(e.target.value, "Etc/UTC", strFormat);
														input.onChange(formatted);
													}}
													hourFormat="12"
													showTime
													showIcon
												/>
												<label htmlFor='dropOffDate'>Drop Off Date and Time:&nbsp;</label>
												<Tooltip target=".custom-target-icon" />
											</span>
										</span>
										<span style={{ width: '15%', display: "inline-block" } }>
											<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
												data-pr-tooltip="Requested date and time for vendor contractor tool drop"
												data-pr-position="right"
												data-pr-at="right+5 center"
												data-pr-my="left center-2"
												style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', marginLeft: "10px" }}>
											</i>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<Field name="serviceDate"
								render={({ input, meta }) => (
									<div style={{ display: "inline-block", width: '100%' }} >
										<span style={{ width: '85%', display: "inline-block" } }>
											<span className="p-float-label">
												<Calendar value={input.value !== "" ? new Date(input.value + "Z") : ""}
													id="serviceDate"
													onChange={(e) => {
														const formatted = formatInTimeZone(e.target.value, "Etc/UTC", strFormat);
														input.onChange(formatted);
													}}
													hourFormat="12"
													showTime
													showIcon
												/>
												<label htmlFor='serviceDate'>Service Date and Time:&nbsp;</label>
												<Tooltip target=".custom-target-icon" />
											</span>
										</span>
										<span style={{ width: '15%', display: "inline-block" } }>
											<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
												data-pr-tooltip="Requested date and time for vendor contractor work hours start"
												data-pr-position="right"
												data-pr-at="right+5 center"
												data-pr-my="left center-2"
												style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', marginLeft: "10px" }}>
											</i>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<p>* May change dates manually after selecting</p>
							{!isManualVendor && (
								<Field name="vendorName"
									render={({ input, meta }) => (
										<div style={{ display: "inline-block",width: '100%' }} >
											<span style={{ width: '85%', display: "inline-block" } }>
												<span className="p-float-label">
													<Dropdown {...input}
														id="vendorName"
														options={vendorOptions}
														optionLabel="label"
													/>
													<label htmlFor='vendorName'>Tool Vendor Name:&nbsp;</label>
													<Tooltip target=".custom-target-icon" />
												</span>
											</span>
											<span style={{ width: '15%', display: "inline-block" } }>
												<i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
													data-pr-tooltip="Tool Drop Vendor Contractor Name (Contractor Company Name)"
													data-pr-position="right"
													data-pr-at="right+5 center"
													data-pr-my="left center-2"
													style={{ fontSize: "1.8rem", display: "inline-block", cursor: 'pointer', marginLeft: "10px" }}>
												</i>
											</span>
											{getFormErrorMessage(meta)}
										</div>
									)}/>
							)}
							{isManualVendor && (
								<Field name="vendorNameManual"
									render={({ input, meta }) => (
										<div>
											<span className="p-float-label">
												<InputText {...input}
													id="vendorNameManual"
													maxLength="100"
												/>
												<label htmlFor='vendorNameManual'>Tool Vendor Name:&nbsp;</label>
											</span>
											{getFormErrorMessage(meta)}
										</div>
									)}/>
							)}
							<Checkbox id="manualVendor"
								checked={isManualVendor}
								onChange={(e) => setIsManualVendor(e.checked)}
								disabled={values.vendorNameManual !== undefined && values.vendorNameManual !== ""}
							/>
							<label htmlFor='manualVendor'>&nbsp;Enter vendor manually</label>
							<Field name="repairCategory"
								render={({ input, meta }) => (
									<div>
										<span className="p-float-label">
											<Dropdown {...input}
												id="repairCategory"
												options={repairCatOptions}
												optionLabel="label"
											/>
											<label htmlFor='repairCategory'>Repair Category:&nbsp;</label>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<Field name="natureOfRepair"
								render={({ input, meta }) => (
									<div>
										<span className="p-float-label">
											<InputTextarea {...input}
												rows='2'
												cols='50'
												id="natureOfRepair"
												maxLength="500"
											/>
											<label htmlFor='natureOfRepair'>Nature of Repair:&nbsp;</label>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)}/>
							<Field name="salesImpact"
								render={({ input, meta }) => (
									<div>
										<span className="p-float-label">
											<Dropdown {...input}
												id="salesImpact"
												options={impactOptions}
												optionLabel="label"
											/>
											<label htmlFor='salesImpact'>Impact to Operations:&nbsp;</label>
										</span>
										{getFormErrorMessage(meta)}
									</div>
								)} />
							<a target="_blank"
								rel="noreferrer"
								href="https://flypittsburgh.com/wp-content/uploads/2020/01/sterile-area-pass-request-form-4_17.pdf"
							>
									SAP Pass
							</a>
							<div className='submit-footer'>
								<Button type="submit" label="Submit" />
							</div>
						</form>
					);
				}}
			/>
		</span>
	);
}
