import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from "../UserContext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { dateOnlyTemplate, dateFromUtcTemplate } from '../service/HelpersService';
import * as FileSaver from 'file-saver';
import { format } from 'date-fns';

const dateFmtStr = "MM/dd/yyyy h:mmaaa";
const dayFmtStr = "MM/dd/yyyy";

export const ToolDropHistory = (props) => {
	const [requests, setRequests] = useState(null);

	const user = useContext(UserContext);

	useEffect(() => {
		user.apiCall(`tooldrophistory/` + user.tenant)
			.then((res) => {
				setRequests(res.data)
			})
	}, [])

	const exportExcel = () => {
		import('xlsx').then(xlsx => {
			const exportData = requests.map((val) => {
				return {
					Email: val.email,
					Request_Date: format(new Date(val.requestDate), dayFmtStr),
					Nature_Of_Repair: val.natureOfRepair,
					Phone_Number: val.phoneNumber,
					Repair_Category: val.repairCategory,
					Sales_Impact: val.salesImpact,
					Service_Date: format(new Date(val.serviceDate + "Z"), dateFmtStr),
					Drop_Off_Date: format(new Date(val.dropOffDate + "Z"), dateFmtStr),
					Vendor_Name: val.vendorName
				}
			})
			const worksheet = xlsx.utils.json_to_sheet(exportData);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			saveAsExcelFile(excelBuffer, 'history');
		});
	}

	const saveAsExcelFile = (buffer, fileName) => {
		let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		let EXCEL_EXTENSION = '.xlsx';
		const data = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	const formatPhone = (rowData) => {
		let formNum = "";

		for (let i = 0; i < rowData.phoneNumber.length; i++) {
			if (formNum.length === 3 || formNum.length === 7 ) {
				formNum += '-';
			}
			if (!isNaN(parseInt(rowData.phoneNumber[i]))) {
				formNum += rowData.phoneNumber[i];
			}
		}

		return `(${formNum})`;
	}

	const tableHeaderTemplate = () => (
		<Button type="button"
			icon="pi pi-file-excel"
			onClick={exportExcel}
			className="p-button-success mr-2"
			data-pr-tooltip="XLS"
			style={{ margin: 2 }}
		/>
	)

	return (
		<DataTable value={requests} header={tableHeaderTemplate}>
			<Column field="email"
				header="Requester"
				align='center'
				alignHeader='center'
			/>
			<Column field="requestDate"
				header="Request Date"
				body={(d) => dateOnlyTemplate(d, "requestDate")}
				align='center'
				alignHeader='center'
			/>
			<Column alignHeader='center'
				field="natureOfRepair"
				header="Nature of Repair"
				align='center'
			/>
			<Column field="phoneNumber"
				header="Phone"
				body={formatPhone}
				align='center'
				alignHeader='center'
			/>
			<Column field="repairCategory"
				header="Repair Category"
				align='center'
				alignHeader='center'
			/>
			<Column field="salesImpact"
				header="Sales Impact"
				align='center'
				alignHeader='center'
			/>
			<Column field="serviceDate"
				header="Service Date"
				body={(d) => dateFromUtcTemplate(d, "serviceDate")}
				align='center'
				alignHeader='center'
			/>
			<Column field="dropOffDate"
				header="Drop Off Date"
				body={(d) => dateFromUtcTemplate(d, "dropOffDate")}
				align='center'
				alignHeader='center'
			/>
			<Column field="vendorName"
				header="Vendor Name"
				align='center'
				alignHeader='center'
			/>
		</DataTable>
	);
}
