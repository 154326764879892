import React from 'react';
import * as FileSaver from 'file-saver';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export const ExportFooterButtons = ({data, name, columns, selection, tableRef} = props) => {
	const exportCSV = (tableRef, selectionOnly) => {
		tableRef.current.exportCSV({ selectionOnly });
	}

	const exportExcel = (tableData, tableName) => {
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(tableData);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

			const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			const EXCEL_EXTENSION = '.xlsx';
			const data = new Blob([excelBuffer], {
				type: EXCEL_TYPE
			});
			FileSaver.saveAs(data, `${tableName}_export_` + new Date().getTime() + EXCEL_EXTENSION);
		});
	}

	const exportPdf = (tableData, tableName, columns) => {
		import('jspdf').then(jsPDF => {
			import('jspdf-autotable').then(() => {
				const doc = new jsPDF.default(0, 0);
				doc.autoTable(columns, tableData);
				doc.save(`export_${tableName}.pdf`);
			})
		})
	}

	return (
		<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<Button type="button"
				icon="pi pi-file"
				onClick={() => exportCSV(tableRef, selection)}
				className="mr-2"
                style={{ margin: 2 }}
                tooltip={"Export to CSV"}
                data-pr-tooltip="Export to CSV"
                tooltipOptions={{ position: 'left' }}
            />
			<Button type="button"
				icon="pi pi-file-excel"
				onClick={() => exportExcel(data, name)}
				className="p-button-success mr-2"
                style={{ margin: 2 }}
                tooltip={"Export to XLSX"}
                data-pr-tooltip="Export to XLSX"
                tooltipOptions={{ position: 'left' }}
            />
			<Button type="button"
				icon="pi pi-file-pdf"
				onClick={() => exportPdf(data, name, columns)}
				className="p-button-warning mr-2"
                tooltip={"Export to PDF"}
                data-pr-tooltip="Export to PDF"
                tooltipOptions={{ position: 'left' }}
				style={{ margin: 2 }} />
		</span>
	)
}
