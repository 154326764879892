import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { UserContext } from "../UserContext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ColumnGroup } from 'primereact/columngroup';
import { Tooltip } from 'primereact/tooltip';
import { ConcessionsContext } from '../service/ConcessionsContext';
import { Row } from 'primereact/row';
export const OperationHours = () => {
    const [templates, setTemplates] = useState([]);
    const [updatedTemplates, setUpdatedTemplates] = useState([]);
    const [initialTable, setInitialTable] = useState([]);
    const [date, setDate] = useState(new Date());
    const [changedRows, setChangedRows] = useState([]);
    const [displayConfirmSaveDialog, setDisplayConfirmSaveDialog] = useState(false);
    const [displayConfirmCopyDialog, setDisplayConfirmCopyDialog] = useState(false);
    const [displayConfirmPublishDialog, setDisplayConfirmPublishDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invalidTime, setInvalidTime] = useState(false);

    const saveSuccessMessage = "Templates Saved Successfully";
    const saveFailMessage = "Failed to Save Templates";
    const publishSuccessMessage = "Published Templates Successfully";
    const publishFailMessage = "Failed to Publish Templates";
    const tooltipMsg = "Select a month/year to enter open and close time for each day of the week for Concessionaire."+'\n'+
    "'Save Template' saves this template for the selected month/year." +'\n'+
    "'Copy From Last Month' copy the previous month's weekly template into the currently selected month/year."+'\n'+ 
    "'Save + Publish' saves the template for the selected month/year and publishes it to the tenants' "+
    "hours page, where each day of the month receives the assigned time based on the template";

    const user = useContext(UserContext);
    const global = useContext(ConcessionsContext);
    const [scrollHeight, setScrollHeight] = useState('80vh');
    useEffect(() => {
        const justDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        user.apiCall(`gettemplates/${justDate}`).then(res => {
            //setUpdatedTemplates(res.data);
            setTemplates(res.data);
            setInitialTable(res.data);
			setChangedRows([])
            setLoading(false)
        });
        const calculateScrollHeight = () => {
            const viewportHeight = window.innerHeight -270;
            const calculatedHeight = viewportHeight; 
            setScrollHeight(`${calculatedHeight}px`);
        };

        calculateScrollHeight(); 

        window.addEventListener('resize', calculateScrollHeight);

        return () => {
            window.removeEventListener('resize', calculateScrollHeight);
        };
    }, [date]);

    useEffect(() => {
        if (templates) {
            const updateTemplates = templates.map(template => ({
                ...template,
                monthNumber: date.getMonth() + 1,
                yearNumber: date.getFullYear(),
				moStartTime :  template.moStartTime!=null? template.moStartTime.slice(0, 5) : null,
				moEndTime: template.moEndTime!=null? template.moEndTime.slice(0, 5) : null,
				tuStartTime :  template.tuStartTime!=null? template.tuStartTime.slice(0, 5) : null,
				tuEndTime: template.tuEndTime!=null? template.tuEndTime.slice(0, 5) : null,
				weStartTime :  template.weStartTime!=null? template.weStartTime.slice(0, 5) : null,
				weEndTime: template.weEndTime!=null? template.weEndTime.slice(0, 5) : null,
				thStartTime :  template.thStartTime!=null? template.thStartTime.slice(0, 5) : null,
				thEndTime: template.thEndTime!=null? template.thEndTime.slice(0, 5) : null,
				friStartTime :  template.friStartTime!=null? template.friStartTime.slice(0, 5) : null,
				friEndTime: template.friEndTime!=null? template.friEndTime.slice(0, 5) : null,
				satStartTime :  template.satStartTime!=null? template.satStartTime.slice(0, 5) : null,
				satEndTime: template.satEndTime!=null? template.satEndTime.slice(0, 5) : null,
				sunStartTime :  template.sunStartTime!=null? template.sunStartTime.slice(0, 5) : null,
				sunEndTime: template.sunEndTime!=null? template.sunEndTime.slice(0, 5) : null,
            }));
            setUpdatedTemplates(updateTemplates);
        }
    }, [templates, date]);

	const saveTemplates = () =>{
		const uniqueChangedRows = getUniqueChangedRows();
        if (uniqueChangedRows.length > 0) {
            user.apiCall('addtemplates', uniqueChangedRows, 'POST')
                .then(res => {
                    setChangedRows([]);
					if(res.status == 200){
                        global.successToast(saveSuccessMessage);
                    }
                    else{
                        global.errorToast(saveFailMessage);
                    }
                });
        }
	}
    const onSaveConfirm = () => {
        saveTemplates()
        setDisplayConfirmSaveDialog(false);
    };

	const headerGroup = (
        <ColumnGroup>
            <Row>
			<Column field="name" header="Concessionaire" rowSpan={2}/>
                <Column header="Monday" colSpan={2} />
				<Column header="Tuesday" colSpan={2}/>
				<Column header="Wednesday" colSpan={2}/>
				<Column header="Thursday" colSpan={2}/>
				<Column header="Friday" colSpan={2}/>
				<Column header="Saturday" colSpan={2}/>
				<Column header="Sunday" colSpan={2}/>
            </Row>
            <Row>
                <Column field="moStartTime" header="Open" />
                <Column field="moEndTime" header="Close" />
                <Column field="tuStartTime" header="Open"  />
                <Column field="tuEndTime" header="Close"  />
                <Column field="weStartTime" header="Open"  />
                <Column field="weEndTime" header="Close"  />
                <Column field="thStartTime" header="Open"  />
                <Column field="thEndTime" header="Close" />
                <Column field="friStartTime" header="Open" />
                <Column field="friEndTime" header="Close" />
                <Column field="satStartTime" header="Open"  />
                <Column field="satEndTime" header="Close" />
                <Column field="sunStartTime" header="Open"  />
                <Column field="sunEndTime" header="Close" />
            </Row>
        </ColumnGroup>
    );
    const onCopyConfirm = () => {
        const justDate = `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        user.apiCall(`copytemplates/${justDate}`, updatedTemplates, 'POST').then(res => {
            setChangedRows(res.data.changedRows);
            //setUpdatedTemplates(res.data.newList);
            setTemplates(res.data.newList);
        });
        

        setDisplayConfirmCopyDialog(false);
    };

    const onPublishConfirm = () => {
		saveTemplates();
		const justDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
		const uniqueChangedRows = getUniqueChangedRows();
		user.apiCall(`converttounittenanthours/${justDate}`, updatedTemplates, 'POST')
                .then(res => {
					user.apiCall('unittenanthours',res.data, 'POST')
						.then(res => {
							setChangedRows([]);
                            if(res.status == 200){
                               global.successToast(publishSuccessMessage);
                            }
                            else{
                               global.errorToast(publishFailMessage);
                            }
						});
                });
        setDisplayConfirmPublishDialog(false);
    };

    const cellEditor = (options) => {
        const { value, editorCallback } = options;

        const handleChange = (e) => {
            let val = e.target.value;
            // Automatically add ':' after two digits
			//if(val.length>5)return;
            if (val.length === 2 && val.indexOf(':') === -1) {
                val += ':';
            }
            editorCallback(val);
        };

        return (
            <InputText
                type="text"
                value={value || ''} // Ensure value is not null
                onChange={handleChange}
                placeholder="(HH:MM)"
                autoFocus
            />
        );
    };

    const validTime = (time) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(time);
    };
	const formatTime = (time) => {
        return time ? time.substring(0, 5) : '';  // Extract hh:mm from hh:mm:ss
    };
    //test needed
    const removeUnUpdatedRow = (rD)=>{
        let match = false;
        const mR = initialTable.find(row => row.unitToTenantId === rD.unitToTenantId);
        if(mR.moStartTime == rD.moStartTime && mR.moEndTime == rD.moEndTime && mR.tuStartTime == rD.tuStartTime && mR.tuEndTime == rD.tuEndTime && mR.weStartTime == rD.weStartTime && mR.weEndTime == rD.weEndTime && mR.thStartTime == rD.thStartTime && mR.thEndTime == rD.thEndTime && mR.friStartTime == rD.friStartTime && mR.friEndTime == rD.friEndTime && mR.satStartTime == rD.satStartTime && mR.satEndTime == rD.satEndTime && mR.sunStartTime == rD.sunStartTime && mR.sunEndTime == rD.sunEndTime)
        {
            const updatedRowsOnly = changedRows.filter(x => x.unitToTenantId !== rD.unitToTenantId);
            setChangedRows(updatedRowsOnly)
        }    
    }
    //
    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        const existingValue = rowData[field];
    //test needed
        let resetFlag = false;
        if(newValue == ":"){
            resetFlag = true;
        }
        if(resetFlag){
            rowData[field] = null;
            //setUpdatedTemplates([...updatedTemplates]);
            setTemplates([...updatedTemplates]);
            removeUnUpdatedRow(rowData);
            return ;
        }

        if ((newValue !== existingValue && validTime(newValue))) {
            switch(field){
                case 'moEndTime':
                case 'tuEndTime':
                case 'weEndTime':
                case 'thEndTime':
                    if (newValue < rowData[field.substring(0, 2) + 'StartTime']) {
                        setInvalidTime(true);
                        return;
                    }
                    break;
                case 'friEndTime':
                case 'satEndTime':
                case 'sunEndTime':
                    if (newValue < rowData[field.substring(0, 3) + 'StartTime']) {
                        setInvalidTime(true);
                        return;
                    }
                    break;
                case 'moStartTime':
                case 'tuStartTime':
                case 'weStartTime':
                case 'thStartTime':
                    if (newValue > rowData[field.substring(0, 2) + 'EndTime']) {
                        setInvalidTime(true);
                        return;
                    }
                    break;
                case 'friStartTime':
                case 'satStartTime':
                case 'sunStartTime':
                    if (newValue > rowData[field.substring(0, 3) + 'EndTime']) {
                        setInvalidTime(true);
                        return;
                    }
                    break; 
            }        
            rowData[field] = newValue;          
            //setUpdatedTemplates([...updatedTemplates]);
            setTemplates([...updatedTemplates]);
            const changedRow = {
                ...rowData,
                [field]: newValue
            };
            setChangedRows(prevRows => [...prevRows.filter(row => !(row.monthNumber === rowData.monthNumber && row.yearNumber === rowData.yearNumber && row.unitToTenantId === rowData.unitToTenantId)), changedRow]);
        } else {
            event.preventDefault();
        }
    };

    const getUniqueChangedRows = () => {
        const uniqueChangedRows = [];
        changedRows.forEach(row => {
            const index = uniqueChangedRows.findIndex(template => template.monthNumber === row.monthNumber && template.yearNumber === row.yearNumber && template.unitToTenantId == row.unitToTenantId);
            if (index === -1) {
                uniqueChangedRows.push(row);
            } else {
                uniqueChangedRows[index] = row; // Update the existing entry with the latest change
            }
        });
        return uniqueChangedRows;
    };

    return (
        <div id="OperationHours">
            <div className="card">
                <div className="grid">
                    <div className="col">
                        <div className="p-field">
                            <label htmlFor="monthyear">Select Month and Year</label>
                            <div className='col-6' style={{ display: 'flex' }}>
                                <Calendar
                                    id="monthyear"
                                    onChange={(e) => setDate(e.value)}
                                    view="month"
                                    value={date}
                                    dateFormat='mm/yy'
                                    style={{ marginRight: '1rem' }}
                                />
                                <Button label="Save Template" onClick={() => setDisplayConfirmSaveDialog(true)} />
                                <Button label="Copy From Last Month" onClick={() => setDisplayConfirmCopyDialog(true)} />
                                <Button label="Save + Publish" onClick={() => setDisplayConfirmPublishDialog(true)} />
                                <Tooltip target=".custo-target-icon" style={{ maxWidth: "30vw" }} />
                                <i className="custo-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                                    data-pr-tooltip={tooltipMsg}
                                    data-pr-position="bottom"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"
                                    style={{ fontSize: "1.8rem", cursor: 'pointer', verticalAlign: "top", margin: "8px 0 0 8px" }}>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog visible={displayConfirmSaveDialog} onHide={() => setDisplayConfirmSaveDialog(false)} header="Confirm">
                    <div>Do you want to save changes?</div>
                    <div className="p-dialog-footer">
                        <Button label="Save" onClick={onSaveConfirm} />
                        <Button label="Cancel" onClick={() => setDisplayConfirmSaveDialog(false)} />
                    </div>
                </Dialog>
                <Dialog visible={displayConfirmCopyDialog} onHide={() => setDisplayConfirmCopyDialog(false)} header="Confirm">
                    <div>Do you want to copy from last month?</div>
                    <div className="p-dialog-footer">
                        <Button label="yes" onClick={onCopyConfirm} />
                        <Button label="no" onClick={() => setDisplayConfirmCopyDialog(false)} />
                    </div>
                </Dialog>
                <Dialog visible={displayConfirmPublishDialog} onHide={() => setDisplayConfirmPublishDialog(false)} header="Confirm">
                    <div>Do you want to publish changes? This action can overwrite previous changes</div>
                    <div className="p-dialog-footer">
                        <Button label="Save" onClick={onPublishConfirm} />
                        <Button label="Cancel" onClick={() => setDisplayConfirmPublishDialog(false)} />
                    </div>
                </Dialog>
                
                <Dialog visible={invalidTime} onHide={() => setInvalidTime(false)} header="Error">
                    <div>End time cannot be more than Start Time</div>
                </Dialog>
                

                <DataTable loading={loading} headerColumnGroup={headerGroup} value={updatedTemplates} rowClassName={rowData => changedRows.some(row => rowData.monthNumber === row.monthNumber && rowData.yearNumber === row.yearNumber && rowData.unitToTenantId == row.unitToTenantId) ? 'changed-row' : ''} showGridlines scrollable scrollHeight={scrollHeight}>
                    <Column field="name" header="Concessionaire" />
                    <Column field="moStartTime" header="Monday_Open" body={rowData => formatTime(rowData.moStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="moEndTime" header="Monday_Close"  body={rowData => formatTime(rowData.moEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="tuStartTime" header="Tuesday_Open" body={rowData => formatTime(rowData.tuStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="tuEndTime" header="Tuesday_Close" body={rowData => formatTime(rowData.tuEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="weStartTime" header="Wednesday_Open" body={rowData => formatTime(rowData.weStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="weEndTime" header="Wednesday_Close" body={rowData => formatTime(rowData.weEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="thStartTime" header="Thursday_Open" body={rowData => formatTime(rowData.thStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="thEndTime" header="Thursday_Close" body={rowData => formatTime(rowData.thEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="friStartTime" header="Friday_Open" body={rowData => formatTime(rowData.friStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="friEndTime" header="Friday_Close" body={rowData => formatTime(rowData.friEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="satStartTime" header="Saturday_Open" body={rowData => formatTime(rowData.satStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="satEndTime" header="Saturday_Close" body={rowData => formatTime(rowData.satEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="sunStartTime" header="Sunday_Open" body={rowData => formatTime(rowData.sunStartTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                    <Column field="sunEndTime" header="Sunday_Close" body={rowData => formatTime(rowData.sunEndTime)} editor={cellEditor} onCellEditComplete={onCellEditComplete} />
                </DataTable>
            </div>
        </div>
    );
};