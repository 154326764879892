import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/AuthConfig";
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';

const AppTopbar = (props) => {
	const op = useRef(null);
	const history = useHistory();
	const goDashboard = () => {
		history.push('/');
	};

	const topbarItemsClassName = classNames('topbar-items fadeInDown', { 'topbar-items-visible': props.topbarMenuActive });
	const profileItemClassName = classNames('profile-item', { 'active-top-menu': props.activeTopbarItem === 'profile' });

	const { instance, accounts } = useMsal();

	function handleLogin(instance) {
		instance.loginRedirect(loginRequest).catch(e => {
			console.error(e);
		});
	}
	function handleLogout(instance) {
		instance.logoutRedirect().catch(e => {
			console.error(e);
		});
	}

	return (
		<div className="topbar clearfix">
			<div className="topbar-left">
				<button style={{float: 'left'}}
					className="p-link"
					onClick={goDashboard}
				>
					<img alt="Logo"
						src="assets/layout/images/logo.png"
						className="topbar-logo"
					/>
				</button>
				<div style={{ fontSize: '1.75rem', color: '#FFF', fontWeight: 'lighter', paddingLeft: '2.8rem', marginTop: '-2px' }}>
                    Concessions
				</div>
			</div>

			<div className="topbar-right">
				<button className="p-link"
					id="menu-button"
					onClick={props.onMenuButtonClick}
				>
					<i className="pi pi-angle-left" />
				</button>

				<button className="p-link"
					id="topbar-menu-button"
					onClick={props.onTopbarMenuButtonClick}
				>
					<i className="pi pi-bars" />
				</button>
				<ul className={topbarItemsClassName}>
					{
						(props.profileMode === 'top' || props.horizontal) && (
							accounts.length > 0 ? (
								<li className={profileItemClassName} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'profile' })}>
									<button className="p-link">
										<span className="topbar-item-name">
											{accounts[0].name}
										</span>
									</button>
									<ul className="layout-menu fadeInDown">
										<li role="menuitem" onClick={() => handleLogout(instance)}>
											<button className="p-link" onClick={null}>
												<i className="pi pi-fw pi-sign-out"></i>
												<span>Sign Out</span>
											</button>
										</li>
									</ul>
								</li>
							) : (
								<li className={profileItemClassName} onClick={(e) => handleLogin(instance)}>
									<button className="p-link">
										<span className="topbar-item-name">Sign In</span>
									</button>
								</li>
							))
					}
					<Tooltip target=".help"
						content="Help"
						position="left" />
					<li >
						<i onClick={(e) => op.current.toggle(e)}
							style={{ fontSize: "16px", color: "white", paddingBottom: "10px" }}
							className="help pi pi-question-circle">
						</i>
						<OverlayPanel ref={op}
							showCloseIcon
							id="overlay_panel" >
                            Email: <a className="help" href="mailto:ACAAConcessions@Flypittsburgh.com">ACAAConcessions@Flypittsburgh.com</a>
						</OverlayPanel>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default AppTopbar;
